
import {ReactComponent as CombinedShape} from '../assets/Combined Shape.svg';
import {ReactComponent as CombinedShapeOne} from '../assets/Combined Shape-1.svg';
import {ReactComponent as ShapeOne} from '../assets/Shape-1.svg';

export const navItems = [
    { key: '1', label: 'Home' },
    { key: '2', label: 'About' },
    { key: '3', label: 'Contact' },
    { key: '4', label: 'Blog' },
  ];



  export const en = {
    language: "english",
    header: {
      companyName: "VERIX",
      services: "Services",
      aboutUs: "About Us",
      blog: "Blog",
      contactUs: "Contact Us",
    },
    footer: {
      companyName: "VERIX",
      menu:{
        title: "MENU",
        about: "About Us",
        service: "Services",
        blog: "Blog",
        contact: "Contact",
      },
      service:{
        title: "SERVICE",
        webDevelopment: "Web Development",
        digitalStrategy: "Digital Strategy",
        softwareSolutions: "Software Solutions",
      },
      bottom: {
        copyRight: "Copyright © 2024 Verix. All Rights Reserved.",
        termsOfUse: "Terms of Use",
        privacyPolicy: "Privacy Policy",
      },
    },
    hero: {
      title: "Your Digital Transformation Partner",
      tagline: "DIGITAL SOLUTIONS",
      paragraph: `We are committed to helping businesses thrive in
                the digital age. With our expertise in custom web
                development, software solutions, and digital
                strategy, we empower you to achieve your goals and
                stay ahead of your competition.`,
      button: "How We Work",
      linkButton: "Contact Us",
    },
    aboutUs: {
      title: "ABOUT US",
      tagline: "An Experienced Saas Agency",
      semiTagline: "Provides a full service range",
      paragraph: `From web development to software solutions, we're
                commited to empowering you with inovative technologies and strategic insights.`,
      linkButton: "Learn More",
    },
    working: {
      title: "HOW WE WORK",
      tagline: "Making Your Projects Look Awesome",
      paragraph: `Combining technical skills, design, business understanding, we provide everything you need to stand out in the digital landscape.`,
      linkButton: "Learn More",
      steps: [
        {
          title: "1",
          description: "Dedicated Team"
        },
        {
          title: "2",
          description: "Tailored Solutions"
        },
        {
          title: "3",
          description: "Fair Pricing"
        },
      ],
    },
    services:{
      title: "SERVICES",
      tagline: "How We can Help",
      services: [
        {
          title: "Web Development",
          icon: <CombinedShape />,
          description: `From responsive websites to e-commerce platforms, 
                        we build tailored solutions that meet your 
                        unique needs and exceed your expectations.`,
          linkButton: "Learn More",
        },
        {
          title: "Digital Strategy",
          icon: <CombinedShapeOne />,
          description: `Helping you reach your target audience, 
                        engage with your customers, and achieve your business
                        objectives through our patnership with Extō Collective.`,
          linkButton: "Discover More",
        },
        {
          title: "Software Solutions",
          icon: <ShapeOne />,
          description: `Whether you need a custom application or a scalable 
                        software solution, we have the expertise to bring your 
                        vision to life and drive business growth.`,
          linkButton: "Explore More",
        },
      ],
    },
    pricing: {
      title: "PRICING",
      tagline: ["Prices tailored made to your Projects", 
                "Guranteed to match Competitor Prices",
                "Fair Prices tailored to your Project"
              ],
      description: ["Combining technical skills, design, business understanding, we provide everything you need to stand out in the digital landscape.",
                    "Combining technical skills, design, business understanding, we provide everything you need to stand out in the digital landscape."],
      linkButton: "Contact Us",  
    },
    promotionSection: {
      title: {
        line1: "We Help you",
        line2: "Build Your",
        line3: "Dream Project",
      },
      tagline: "As a SaaS agency we are a company you hire to manage your digital initiatives externally rather than in-house.",
      button: "ABOUT US",
    },
    blogSection: {
      title: "OUR BLOGS",
      tagline: "Latest Blog Articles.",
      button: "Discover All",
      articles: [
        {
          category: "Stories",
          title: "Agency is a business you hire to outsource",
          date: "5 Nov, 2021",
        },
        {
          category: "Design",
          title: "Outsource your digital marketing efforts",
          date: "29 Oct, 2021",
        },
        {
          category: "Marketing",
          title: "Your business with a variety of digital",
          date: "21 Oct, 2021",
        },
      ],
    },
    faq: {
      title: "FAQ",
      tagline: "Frequently Asked Questions",
      description: `As a SaaS agency we are a company you hire to manage your digital initiatives
                    externally rather than in-house.`,
      linkButton: "Contact Us",
      questions: [
        {
          title: "Industries we typically work with",
          description: `We work with a wide range of industries, including technology, healthcare, finance, and retail.  
          Our team has the expertise to deliver custom solutions that meet the unique needs of your business.`,
        },
        {
          title: "Projects of different sizes",
          description: 
          `We work with businesses of all sizes, from startups to large enterprises.
          Our team has the experience and skills to deliver projects of any size and complexity.`,
        },
        {
          title: "Post-Project Support",
          description: 
          `We provide ongoing support and maintenance to ensure that your project continues to meet your needs and
          delivers value to your business.`,
        },
        {
          title: "Project Timelines",
          description:
          `The timeline for your project depends on the scope and complexity of the work.
          We work with you to create a timeline that meets your needs and ensures that your project is delivered on time.`,
        }
      ],
    },
    contact: {
      title: "Contact",
      tagline: `Agency provides a full service range including technical skills,
design, business understanding.`,
      button: "Sign Up Now",
      contactForm: {
        title: "Contact Us",
        phone: "PHONE",
        email: "EMAIL",
        phoneDetail: "076 123 45 67",
        emailDetail: "info@verix.se",
        formTitle: "Get In Touch",
        generalInquiry: "General Inquiry",
        support: "Support",
        feedback: "Feedback",
        emailPlaceholder: "Your Email",
        subjectPlaceholder: "Subject",
        messagePlaceholder: "Message",
        submitButton: "SUBMIT NOW",
      },
    },
    aboutUsPage: {
      title: "About Us",
      tagline: `Verix provides a full service range including technical skills,
design, business understanding.`,
      aboutUsComponent: {
        title: "ABOUT US",
        tagline: "An Experienced Saas Agency",
        semiTagline: "Provides a full service range",
        paragraph: `From web development to software solutions, we’re committed to empowering you with innovative technologies and strategic insights. At Verix, we are dedicated to transforming businesses through innovative digital solutions that foster growth and success. Our goal is to surpass client expectations by delivering cutting-edge solutions, personalised strategies, and unparalleled service.`,
        cards: [
          { img: "https://via.placeholder.com/450" },
          { img: "https://via.placeholder.com/150" },
          { img: "https://via.placeholder.com/150" },
          { img: "https://via.placeholder.com/150" },
          { img: "https://via.placeholder.com/150" },
        ],
      },
      ourStoryComponent: {
        title: "OUR STORY",
        tagline: `Commitment to Innovation.`,
        paragraph: `Verix began as a vision to redeﬁne the digital landscape, founded by a group of passionate individuals driven by innovation and
                    excellence. Our journey started with a simple belief: that businesses deserve more than just standard solutions – they deserve
                    personalized, transformative experiences. From our humble beginnings, we’ve grown into a trusted partner for businesses seeking
                    to navigate the complexities of the digital world. Our story is one of perseverance, creativity, and unwavering dedication to our
                    clients’ success. With each project and partnership, we continue to write new chapters in our story, fueled by the passion to push
                    boundaries and unlock endless possibilities in the digital realm.
                    At Verix, our mission is to empower businesses with transformative digital solutions that drive innovation, growth, and success.
                    We strive to exceed our clients’ expectations by delivering cutting-edge technologies, personalized strategies, and exceptional
                    service. Through collaboration, creativity, and a commitment to excellence, we aim to be the trusted partner that helps
                    businesses navigate the complexities of the digital landscape and achieve their full potential.`,
      },
      faqComponent: {
        title: "FAQ",
        tagline: "Frequently Asked Questions",
        description: `As a SaaS agency we are a company you hire to manage your digital initiatives
                      externally rather than in-house.`,
        questions: [
          {
            title: "Industries we typically work with",
            description: `We work with a wide range of industries, including technology, healthcare, finance, and retail.  
            Our team has the expertise to deliver custom solutions that meet the unique needs of your business.`,
          },
          {
            title: "Projects of different sizes",
            description: 
            `We work with businesses of all sizes, from startups to large enterprises.
            Our team has the experience and skills to deliver projects of any size and complexity.`,
          },
          {
            title: "Post-Project Support",
            description: 
            `We provide ongoing support and maintenance to ensure that your project continues to meet your needs and
            delivers value to your business.`,
          },
          {
            title: "Project Timelines",
            description:
            `The timeline for your project depends on the scope and complexity of the work.
            We work with you to create a timeline that meets your needs and ensures that your project is delivered on time.`,
          }
        ],
      },
     
    },
    blogsPage: {
      title: "Blogs",
      tagline: `Verix provides a full service range including technical skills,
      design, business understanding.`,
      blogComponent: {
        title: "Blogs",
        tagline: "Stay up to date with the latest trends and insights in the digital world.",
        paragraph: `Our blog is a valuable resource for businesses looking to stay ahead of the curve in the digital landscape.
        From industry insights to expert advice, we cover a wide range of topics to help you navigate the complexities of the digital world.`,
        loadMoreButton: "LOAD MORE",
        newsletter: "Newsletter",
        emailPlaceholder: "Your Email",
        subscribeButton: "SUBSCRIBE",
        cards: [
          {
            id: 1,
            title: "Agency is a business you hire to outsource",
            date: "5 Nov, 2021",
            category: "Stories",
            thumbnail: "https://via.placeholder.com/450",
            content: "Agencies provide specialized services to help businesses achieve their goals. Whether it's marketing, design, or development, agencies bring a wealth of knowledge and experience to the table. Outsourcing your needs to an agency can save time, reduce costs, and bring fresh perspectives to your business."
          },
          {
            id: 2,
            title: "Outsource your digital marketing efforts",
            date: "29 Oct, 2021",
            category: "Design",
            thumbnail: "https://via.placeholder.com/450",
            content: `
              Outsourcing your digital marketing efforts can help you reach new audiences, increase brand awareness, and drive sales. Agencies have the expertise and resources to create effective campaigns that deliver results. By partnering with an agency, you can focus on running your business while experts handle your marketing needs.
            `
          },
          {
            id: 3,
            title: "Your business with a variety of digital",
            date: "21 Oct, 2021",
            category: "Marketing",
            thumbnail: "https://via.placeholder.com/250",
            content: "Utilizing a variety of digital tools can enhance your business operations. From analytics to automation, digital tools provide insights, increase efficiency, and improve customer engagement. Learn how to integrate these tools effectively to drive growth and stay competitive."
          },
          {
            id: 4,
            title: "Understanding Digital Marketing",
            date: "18 Oct, 2021",
            category: "Business",
            thumbnail: "https://via.placeholder.com/250",
            content: "Digital marketing involves various strategies such as SEO, content marketing, and email campaigns to promote your business online. Understanding how these strategies work together can help you create a cohesive plan that drives traffic, builds brand awareness, and converts leads into customers."
          },
          {
            id: 5,
            title: "Guide to a Successful Agency",
            date: "10 Oct, 2021",
            category: "Growth",
            thumbnail: "https://via.placeholder.com/250",
            content: "Running a successful agency requires a clear vision, strong leadership, and a focus on customer satisfaction. Learn the key factors that contribute to agency success, including building a strong team, effective communication, and continuous improvement in service delivery."
          },
          {
            id: 6,
            title: "Marketing with Digital Tools",
            date: "7 Oct, 2021",
            category: "Tools",
            thumbnail: "https://via.placeholder.com/250",
            content: "Digital tools like analytics software, CRM systems, and automation platforms are essential for modern marketing. Discover how these tools can streamline your marketing efforts, provide valuable insights, and help you achieve better results with less effort."
          },
          {
            id: 7,
            title: "Advanced Marketing Techniques",
            date: "4 Oct, 2021",
            category: "Advanced",
            thumbnail: "https://via.placeholder.com/250",
            content: "Explore advanced marketing techniques that can elevate your campaigns to the next level. Learn about data-driven marketing, personalized customer experiences, and leveraging AI to create more effective strategies and achieve better ROI."
          },
          {
            id: 8,
            title: "Building an Effective Online Presence",
            date: "1 Oct, 2021",
            category: "Strategy",
            thumbnail: "https://via.placeholder.com/250",
            content: "An effective online presence is crucial in today's digital world. Discover strategies to build a strong online footprint, including optimizing your website, engaging on social media, and creating valuable content that resonates with your audience."
          }
        ],        
      },
    },
    servicesPage: {
      title: "Services",
      tagline: `Verix provides a full service range including technical skills,
      design, business understanding.`,
      servicesComponent: {
        title: "SERVICES",
        tagline: `From web development to software
                  solutions, we’re committed to
                  empowering you with innovative
                  technologies and strategic insights.`,
        paragraph: `Ability to put themselves in the merchant's shoes. It is meant
                    to partner on the long run, and work as an extension of the
                    merchant's team.
                    A digital agency is a business you hire to outsource your digital
                    marketing efforts, instead of handling in-house. They can
                    provide your business with a variety of digital solutions to
                    promote your product or service online and help you.`,
        services: [
          {
            title: "Web Development",
            icon: <CombinedShape />,
            tagline: `From responsive websites to e-commerce platforms, we
                      build tailored solutions that meet your unique needs and
                      exceed your expectations.`,
            description: `That’s why we go beyond just building websites and e-
                          commerce platforms – we create dynamic digital experiences
                          that not only meet your current needs but also anticipate
                          future requirements. Our team of experts leverage the latest
                          tools and techniques to ensure that your digital presence
                          remains relevant and impactful in an ever-changing market.`,
            detailsCard: [
              {
                title: `Personalised websites and
e-commerce platforms.`,
              },
              {
                title: `Innovative online presence.`,
               
              },
              {
                title: `Strong digital infrastructure.`,
               
              },
              {
                title: `Expert guidance at every step.`,
               
              },
            ],
            linkButton: "LET’S GET STARTED",
          },
          {
            title: "Digital Strategy",
            icon: <CombinedShapeOne />,
            tagline: `Helping you reach your target audience, engage with
                      your customers, and achieve your business objectives
                      through our partnership with Extō Collective.`,
            description: `Our collaboration with Extō Collective extends beyond building
                          websites and platforms. Together, we leverage our combined
                          expertise to help you reach your target audience, engage with
                          your customers, and achieve your business objectives. By
                          integrating strategic marketing insights and creative
                          storytelling, we ensure that your brand resonates with your
                          audience on a deeper level, driving meaningful connections and
                          fostering brand loyalty.`,
                          detailsCard: [
                            {
                              title: `Creation of immersive digital
experiences.`,
                            },
                            {
                              title: `Development of captivating
content.`,
                             
                            },
                            {
                              title: `Establishment of brand loyalty
and measurable business growth.`,
                             
                            },
                            {
                              title: `Strategic SEO optimisation.`,
                             
                            },
                          ],
            linkButton: "LET’S GET STARTED",
          },
          {
            title: "Software Solutions",
            icon: <ShapeOne />,
            tagline: `Whether you need a custom application or a scalable
software solution, we have the expertise to bring your
vision to life and drive business growth.`,
            description: `At Verix, we understand that the success of your business relies
on having the right technology in place. Whether you’re in need
development.
of a custom application tailored to your unique speciﬁcations or
a scalable software solution to support your growing
operations, we have the expertise and resources to bring your
vision to life.`,
                          detailsCard: [
                            {
                              title: `Application development.`,
                            },
                            {
                              title: `Scalable software solutions.`,
                             
                            },
                            {
                              title: `Innovative and reliable
approaches.`,
                             
                            },
                            {
                              title: `Adaptable software
development.`,
                             
                            },
                          ],
                          linkButton: "LET’S GET STARTED",
          },
        ],
      },
    },
  };
  

  export const sv = {
    language: "sweedish",
    header: {
      companyName: "VERIX",
      services: "Tjänster",
      aboutUs: "Om Oss",
      blog: "Blogg",
      contactUs: "Kontakta Oss",
    },
footer: {
      companyName: "VERIX",
      menu: {
        title: "MENY",
        about: "Om Oss",
        service: "TJÄNSTER",
        blog: "Blogg",
        contact: "Kontakt",
      },
      service: {
        title: "TJÄNSTER",
        webDevelopment: "Webbutveckling",
        digitalStrategy: "Digital Strategi",
        softwareSolutions: "Mjukvarulösningar",
      },
      bottom: {
        copyRight: "Copyright © 2024 Verix. Alla rättigheter förbehållna.",
        termsOfUse: "Användarvillkor",
        privacyPolicy: "Integritetspolicy",
      },
    },

    hero: {
      title: "Din Partner för Digital Transformation",
      tagline: "DIGITALA LÖSNINGAR",
      paragraph: `Vi är engagerade i att hjälpa företag att blomstra i
                den digitala eran. Med vår expertis inom skräddarsydd webbutveckling,
                mjukvarulösningar och digital strategi, ger vi dig verktygen att nå dina mål
                och ligga steget före din konkurrens.`,
      button: "Hur Vi Arbetar",
      linkButton: "Kontakta Oss",
    },

    aboutUs: {
      title: "OM OSS",
      tagline: "En Erfaren SaaS-Byrå",
      semiTagline: "Erbjuder ett komplett tjänsteutbud",
      paragraph: `Från webbutveckling till mjukvarulösningar är vi
                engagerade i att ge dig innovativa teknologier och strategiska insikter.`,
      linkButton: "Läs Mer",
    },

    working: {
      title: "HUR VI ARBETAR",
      tagline: "Få Dina Projekt att Se Fantastiska Ut",
      paragraph: `Genom att kombinera tekniska färdigheter, design och affärsförståelse erbjuder vi allt du behöver för att sticka ut i den digitala världen.`,
      linkButton: "Läs Mer",
      steps: [
        {
          title: "1",
          description: "Dedikerat Team"
        },
        {
          title: "2",
          description: "Skräddarsydda Lösningar"
        },
        {
          title: "3",
          description: "Rättvisa Priser"
        },
      ],
    },

    services: {
      title: "TJÄNSTER",
      tagline: "Hur Vi Kan Hjälpa",
      services: [
        {
          title: "Webbutveckling",
          icon: <CombinedShape />,
          description: `Från responsiva webbplatser till e-handelsplattformar, 
                        bygger vi skräddarsydda lösningar som möter dina 
                        unika behov och överträffar dina förväntningar.`,
          linkButton: "Läs Mer",
        },
        {
          title: "Digital Strategi",
          icon: <CombinedShapeOne />,
          description: `Hjälper dig att nå din målgrupp, 
                        engagera dina kunder och uppnå dina affärsmål 
                        genom vårt partnerskap med Extō Collective.`,
          linkButton: "Upptäck Mer",
        },
        {
          title: "Mjukvarulösningar",
          icon: <ShapeOne />,
          description: `Oavsett om du behöver en skräddarsydd applikation eller en skalbar 
                        mjukvarulösning, har vi expertisen för att förverkliga din 
                        vision och driva affärstillväxt.`,
          linkButton: "Utforska Mer",
        },
      ],
    },

    pricing: {
      title: "PRISER",
      tagline: ["Priser skräddarsydda för dina projekt", 
                "Garanterat att matcha konkurrentpriser",
                "Rättvisa priser anpassade efter ditt projekt"
              ],
      description: ["Genom att kombinera tekniska färdigheter, design och affärsförståelse erbjuder vi allt du behöver för att sticka ut i den digitala världen.",
                    "Genom att kombinera tekniska färdigheter, design och affärsförståelse erbjuder vi allt du behöver för att sticka ut i den digitala världen."],
      linkButton: "Kontakta Oss",  
    },
    promotionSection: {
      title: {
        line1: "Vi Hjälper dig",
        line2: "Bygga Ditt",
        line3: "Drömprojekt",
      },
      tagline: "Som en SaaS-byrå är vi ett företag du anlitar för att hantera dina digitala initiativ externt istället för internt.",
      button: "OM OSS",
    },
    blogSection: {
      title: "VÅRA BLOGGAR",
      tagline: "Senaste Bloggartiklar.",
      button: "Upptäck Alla",
      articles: [
        {
          category: "Berättelser",
          title: "Byrå är ett företag du anlitar för att outsourca",
          date: "5 nov, 2021",
        },
        {
          category: "Design",
          title: "Outsourca dina digitala marknadsföringsinsatser",
          date: "29 okt, 2021",
        },
        {
          category: "Marknadsföring",
          title: "Ditt företag med en mängd digitala",
          date: "21 okt, 2021",
        },
      ],
    },



    faq: {
      title: "FAQ",
      tagline: "Vanliga Frågor",
      description: `Vi förstår att du kan ha frågor om våra tjänster. 
                    Här är några av de vanligaste frågor vi får.`,
      linkButton: "Kontakta Oss",
      questions: [
        {
          title: "Vad är processen för att arbeta med er?",
          description: `Vi börjar med att förstå ditt företag och dina mål. 
                        Vi skapar sedan en skräddarsydd lösning som möter dina 
                        unika behov och överträffar dina förväntningar.`,
        },
        {
          title: "Hur mycket kostar en webbplats?",
          description: `Kostnaden för en webbplats beror på dina krav. 
                        Vi erbjuder en rad prissättningsalternativ som passar din budget.`,
        },
        {
          title: "Hur lång tid tar det att bygga en webbplats?",
          description: `Tiden det tar att bygga en webbplats beror på dina krav. 
                        Vi arbetar med dig för att skapa en tidslinje som möter dina behov.`,
        },
      ],
    },

    contact: {
      title: "Kontakt",
      tagline: `Byrå erbjuder ett komplett tjänsteutbud som inkluderar tekniska färdigheter,
design och affärsförståelse.`,
      button: "Anmäl Dig Nu",
      contactForm: {
        title: "Kontakta Oss",
        phone: "TELEFON",
        email: "E-POST",
        phoneDetail: "076 123 45 67",
        emailDetail: "info@verix.se",
        formTitle: "Kom i Kontakt",
        generalInquiry: "Allmän Förfrågan",
        support: "Support",
        feedback: "Feedback",
        emailPlaceholder: "Din E-post",
        subjectPlaceholder: "Ämne",
        messagePlaceholder: "Meddelande",
        submitButton: "SKICKA NU",
      },

    },

    aboutUsPage: {
      title: "Om Oss",
      tagline: `Verix erbjuder ett komplett tjänsteutbud som inkluderar tekniska färdigheter,
design och affärsförståelse.`,
      aboutUsComponent: {
        title: "OM OSS",
        tagline: "En Erfaren SaaS Byrå",
        semiTagline: "Erbjuder ett komplett tjänsteutbud",
        paragraph: `Från webbutveckling till mjukvarulösningar är vi engagerade i att stärka dig med innovativ teknologi och strategiska insikter. På Verix är vi dedikerade till att transformera företag genom innovativa digitala lösningar som främjar tillväxt och framgång. Vårt mål är att överträffa kundernas förväntningar genom att leverera banbrytande lösningar, personliga strategier och oöverträffad service.`,
        cards: [
          { img: "https://via.placeholder.com/450" },
          { img: "https://via.placeholder.com/150" },
          { img: "https://via.placeholder.com/150" },
          { img: "https://via.placeholder.com/150" },
          { img: "https://via.placeholder.com/150" },
        ],
      },
      ourStoryComponent: {
        title: "VÅR HISTORIA",
        tagline: `Engagemang för Innovation.`,
        paragraph: `Verix började som en vision att omdefiniera den digitala landskapet, grundad av en grupp passionerade individer driva av innovation och
                    excellens. Vår resa började med en enkel tro: att företag förtjänar mer än bara standardlösningar – de förtjänar
                    personliga, transformerande upplevelser. Från våra blygsamma början har vi vuxit till en betrodd partner för företag som söker
                    navigera i den digitala världens komplexiteter. Vår historia är en av uthållighet, kreativitet och orubbligt engagemang för vår
                    kunders framgång. Med varje projekt och partnerskap fortsätter vi att skriva nya kapitel i vår historia, drivet av passionen att
                    tänja på gränserna och låsa upp oändliga möjligheter i den digitala världen.
                    På Verix är vår mission att stärka företag med transformerande digitala lösningar som driver innovation, tillväxt och framgång.
                    Vi strävar efter att överträffa våra kunders förväntningar genom att leverera banbrytande teknologi, personliga strategier och
                    exceptionell service. Genom samarbete, kreativitet och ett engagemang för excellens, syftar vi till att vara den betrodda partnern
                    som hjälper företag att navigera i den digitala landskapets komplexiteter och nå sin fulla potential.`,
      },
      faqComponent: {
        title: "FAQ",
        tagline: "Vanliga Frågor",
        description: `Som en SaaS-byrå är vi ett företag du anlitar för att hantera dina digitala initiativ
                      externt snarare än internt.`,
        questions: [
          {
            title: "Branscher vi vanligtvis arbetar med",
            description: `Vi arbetar med en mängd olika branscher, inklusive teknik, hälsovård, finans och detaljhandel.  
            Vårt team har expertisen att leverera skräddarsydda lösningar som möter ditt företags unika behov.`,
          },
          {
            title: "Projekt av olika storlekar",
            description: 
            `Vi arbetar med företag av alla storlekar, från startups till stora företag.
            Vårt team har erfarenheten och färdigheterna att leverera projekt av alla storlekar och komplexitet.`,
          },
          {
            title: "Efter-projekt stöd",
            description: 
            `Vi erbjuder löpande stöd och underhåll för att säkerställa att ditt projekt fortsätter att möta dina behov och
            levererar värde till ditt företag.`,
          },
          {
            title: "Projekt-tidslinjer",
            description:
            `Tidslinjen för ditt projekt beror på omfattningen och komplexiteten av arbetet.
            Vi arbetar med dig för att skapa en tidslinje som möter dina behov och säkerställer att ditt projekt levereras i tid.`,
          }
        ],
      },
     
    },

    blogsPage: {
      title: "Bloggar",
      tagline: `Verix erbjuder ett komplett tjänsteutbud som inkluderar tekniska färdigheter,
      design och affärsförståelse.`,
      blogComponent: {
        title: "Bloggar",
        tagline: "Håll dig uppdaterad med de senaste trenderna och insikterna i den digitala världen.",
        paragraph: `Vår blogg är en värdefull resurs för företag som vill ligga steget före i den digitala landskapet.
        Från branschinsikter till expertutlåtanden, täcker vi ett brett spektrum av ämnen för att hjälpa dig navigera i den digitala världens komplexiteter.`,
        loadMoreButton: "LÄS MER",
        newsletter: "Nyhetsbrev",
        emailPlaceholder: "Din E-post",
        subscribeButton: "PRENUMERERA",
        cards: [
          {
            id: 1,
            title: "Byrå är en verksamhet du anlitar för att outsourca",
            date: "5 nov, 2021",
            category: "Berättelser",
            thumbnail: "https://via.placeholder.com/450",
            content: "Byråer erbjuder specialiserade tjänster för att hjälpa företag att uppnå sina mål. Oavsett om det handlar om marknadsföring, design eller utveckling, tillför byråer en mängd kunskaper och erfarenheter. Att outsourca dina behov till en byrå kan spara tid, minska kostnader och ge nya perspektiv till ditt företag."
          },
          {
            id: 2,
            title: "Outsource dina digitala marknadsföringsinsatser",
            date: "29 okt, 2021",
            category: "Design",
            thumbnail: "https://via.placeholder.com/450",
            content: `
              Att outsourca dina digitala marknadsföringsinsatser kan hjälpa dig att nå nya målgrupper, öka varumärkeskännedomen och driva försäljning. Byråer har expertisen och resurserna för att skapa effektiva kampanjer som ger resultat. Genom att samarbeta med en byrå kan du fokusera på att driva ditt företag medan experter hanterar dina marknadsföringsbehov.
            `
          },
          {
            id: 3,
            title: "Ditt företag med en mängd digitala",
            date: "21 okt, 2021",
            category: "Marknadsföring",
            thumbnail: "https://via.placeholder.com/250",
            content: "Att använda en mängd digitala verktyg kan förbättra din företagsverksamhet. Från analys till automatisering, digitala verktyg ger insikter, ökar effektiviteten och förbättrar kundengagemanget. Lär dig hur du integrerar dessa verktyg effektivt för att driva tillväxt och förbli konkurrenskraftig."
          },
          {
            id: 4,
            title: "Förstå Digital Marknadsföring",
            date: "18 okt, 2021",
            category: "Affärer",
            thumbnail: "https://via.placeholder.com/250",
            content: "Digital marknadsföring innefattar olika strategier som SEO, innehållsmarknadsföring och e-postkampanjer för att marknadsföra ditt företag online. Att förstå hur dessa strategier samverkar kan hjälpa dig att skapa en sammanhängande plan som driver trafik, bygger varumärkeskännedom och omvandlar leads till kunder."
          },
          {
            id: 5,
            title: "Guide till en Framgångsrik Byrå",
            date: "10 okt, 2021",
            category: "Tillväxt",
            thumbnail: "https://via.placeholder.com/250",
            content: "Att driva en framgångsrik byrå kräver en tydlig vision, starkt ledarskap och fokus på kundnöjdhet. Lär dig de viktigaste faktorerna som bidrar till byråns framgång, inklusive att bygga ett starkt team, effektiv kommunikation och kontinuerlig förbättring av tjänsteleveransen."
          },
          {
            id: 6,
            title: "Marknadsföring med Digitala Verktyg",
            date: "7 okt, 2021",
            category: "Verktyg",
            thumbnail: "https://via.placeholder.com/250",
            content: "Digitala verktyg som analysprogramvara, CRM-system och automatiseringsplattformar är avgörande för modern marknadsföring. Upptäck hur dessa verktyg kan effektivisera dina marknadsföringsinsatser, ge värdefulla insikter och hjälpa dig att uppnå bättre resultat med mindre ansträngning."
          },
          {
            id: 7,
            title: "Avancerade Marknadsföringstekniker",
            date: "4 okt, 2021",
            category: "Avancerad",
            thumbnail: "https://via.placeholder.com/250",
            content: "Utforska avancerade marknadsföringstekniker som kan lyfta dina kampanjer till nästa nivå. Lär dig om datadriven marknadsföring, personliga kundupplevelser och hur du använder AI för att skapa mer effektiva strategier och uppnå bättre ROI."
          },
          {
            id: 8,
            title: "Bygga en Effektiv Online-Närvaro",
            date: "1 okt, 2021",
            category: "Strategi",
            thumbnail: "https://via.placeholder.com/250",
            content: "En effektiv online-närvaro är avgörande i dagens digitala värld. Upptäck strategier för att bygga en stark online-närvaro, inklusive att optimera din webbplats, engagera dig på sociala medier och skapa värdefullt innehåll som resonerar med din publik."
          }
        ],        
      },
    },


    servicesPage: {
      title: "Tjänster",
      tagline: `Verix erbjuder ett komplett tjänsteutbud som inkluderar tekniska färdigheter,
      design och affärsförståelse.`,
      servicesComponent: {
        title: "TJÄNSTER",
        tagline: `Från webbutveckling till mjukvarulösningar är vi engagerade i
                  att stärka dig med innovativ teknologi och strategiska insikter.`,
        paragraph: `Förmåga att sätta sig in i handlarens situation. Det är menat
                    att samarbeta på lång sikt och arbeta som en förlängning av
                    handlarens team.
                    En digital byrå är en verksamhet du anlitar för att outsourca din digitala
                    marknadsföring istället för att hantera den internt. De kan
                    erbjuda ditt företag en mängd digitala lösningar för att
                    marknadsföra din produkt eller tjänst online och hjälpa dig.`,
        services: [
          {
            title: "Webbutveckling",
            icon: <CombinedShape />,
            tagline: `Från responsiva webbplatser till e-handelsplattformar, vi
                      bygger skräddarsydda lösningar som uppfyller dina unika behov och
                      överträffar dina förväntningar.`,
            description: `Det är därför vi går bortom att bara bygga webbplatser och e-
                          handelsplattformar – vi skapar dynamiska digitala upplevelser
                          som inte bara möter dina aktuella behov utan också förutser
                          framtida krav. Vårt team av experter använder de senaste
                          verktygen och teknikerna för att säkerställa att din digitala närvaro
                          förblir relevant och effektiv på en ständigt förändrad marknad.`,
            detailsCard: [
              {
                title: `Personliga webbplatser och
e-handelsplattformar.`,
              },
              {
                title: `Innovativ online-närvaro.`,
               
              },
              {
                title: `Stark digital infrastruktur.`,
               
              },
              {
                title: `Expertvägledning vid varje steg.`,
               
              },
            ],
            linkButton: "LÅT OSS KOMMA IGÅNG",
          },
          {
            title: "Digital strategi",
            icon: <CombinedShapeOne />,
            tagline: `Hjälper dig att nå din målgrupp, engagera dina
                      kunder och uppnå dina affärsmål genom vårt partnerskap med Extō Collective.`,
            description: `Vårt samarbete med Extō Collective sträcker sig bortom att bygga
                          webbplatser och plattformar. Tillsammans utnyttjar vi vår kombinerade
                          expertis för att hjälpa dig att nå din målgrupp, engagera dina
                          kunder och uppnå dina affärsmål. Genom att integrera strategiska
                          marknadsinsikter och kreativ berättarteknik, säkerställer vi att ditt
                          varumärke resonerar med din publik på en djupare nivå, driver
                          meningsfulla kopplingar och främjar varumärkeslojalitet.`,
                          detailsCard: [
                            {
                              title: `Skapande av uppslukande digitala
upplevelser.`,
                            },
                            {
                              title: `Utveckling av fängslande
innehåll.`,
                             
                            },
                            {
                              title: `Etablering av varumärkeslojalitet
och mätbar affärstillväxt.`,
                             
                            },
                            {
                              title: `Strategisk SEO-optimering.`,
                             
                            },
                          ],
            linkButton: "LÅT OSS KOMMA IGÅNG",
          },
          {
            title: "Mjukvarulösningar",
            icon: <ShapeOne />,
            tagline: `Oavsett om du behöver en skräddarsydd applikation eller en skalbar
mjukvarulösning, har vi expertisen för att förverkliga din
vision och driva affärstillväxt.`,
            description: `På Verix förstår vi att framgången för ditt företag beror på att du har
rätt teknologi på plats. Oavsett om du behöver en skräddarsydd applikation
som är anpassad efter dina unika specifikationer eller en skalbar
mjukvarulösning för att stödja din växande verksamhet, har vi
expertisen och resurserna för att förverkliga din vision.`,
                          detailsCard: [
                            {
                              title: `Applikationsutveckling.`,
                            },
                            {
                              title: `Skalbara mjukvarulösningar.`,
                             
                            },
                            {
                              title: `Innovativa och tillförlitliga
metoder.`,
                             
                            },
                            {
                              title: `Anpassningsbar mjukvaruutveckling.`,
                             
                            },
                          ],
                          linkButton: "LÅT OSS KOMMA IGÅNG",
          },
        ],
      },
    },

  };
  